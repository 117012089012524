<template>
    <div>
        <ts-page-title
            :title="$t('employeeProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('employeeProfile.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between tw-items-center">
                    <div>
                        <a-button
                            type="primary"
                            v-if="$can('create-employee-profiles')"
                            @click="addNew"
                        >
                            {{ $t("addNew") }}
                        </a-button>
                    </div>
                    <div class="tw-space-x-3">
                        <a-tooltip :title="$t('employeeProfile.search')">
                            <a-input-search
                                v-model="search"
                                :placeholder="$t('employeeProfile.search')"
                                style="width: 200px"
                            />
                        </a-tooltip>
                    </div>
                </div>
            </ts-panel-wrapper>
            <div class="tw-overflow-x-scroll">
                <ts-table
                    :columns="columns"
                    :records="resources"
                    :loading="loading"
                >
                    <template v-slot="{ record }">
                        <td>{{ record.card_id }}</td>
                        <td>{{ record.employee_name_kh }}</td>
                        <td>{{ record.employee_name_en }}</td>
                        <td class="tw-capitalize">{{ record.sex }}</td>
                        <td>{{ record.position_name_en }}</td>
                        <td>{{ record.date_of_birth }}</td>
                        <td>{{ record.phone }}</td>
                        <td>{{ record.status }}</td>
                        <td class="text-center tw-space-x-3">
                            <template v-if="!record.is_deleted">
                                <a
                                    v-if="$can('update-employee-profiles')"
                                    href="#"
                                    v-tooltip="$t('edit')"
                                    class="text-primary mr-2"
                                    @click.prevent="edit(record)"
                                >
                                    <i class="fas fa-edit"></i>
                                </a>

                                <a-popconfirm
                                    v-if="$can('delete-employee-profiles')"
                                    placement="leftTop"
                                    :title="$t('are_you_sure_to_delete')"
                                    :ok-text="$t('yes')"
                                    :cancel-text="$t('no')"
                                    @confirm="deleteRecord(record)"
                                >
                                    <a href="#" :disabled="record._deleting">
                                        <i
                                            class="fas fa-circle-notch spin"
                                            v-if="record._deleting"
                                        ></i>
                                        <i
                                            class="fa fa-trash text-red"
                                            v-else
                                        ></i>
                                    </a>
                                </a-popconfirm>
                            </template>
                        </td>
                    </template>
                </ts-table>
            </div>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
    name: "employeeProfileIndex",
    data() {
        return {
            loading: false
        };
    },
    computed: {
        ...mapState("hr/employeeProfile", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.hr.employeeProfile.search;
            },
            set(newValue) {
                this.$store.commit("hr/employeeProfile/SET_SEARCH", newValue);
                this.$store.commit("hr/employeeProfile/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                { name: this.$t("employeeProfile.idCard"), sortKey: 'card_id' },
                { name: this.$t("employeeProfile.nameKh"), sortKey: 'employee_name_kh' },
                { name: this.$t("employeeProfile.nameEn"), sortKey: 'employee_name_en' },
                { name: this.$t("employeeProfile.sex") },
                { name: this.$t("employeeProfile.position"), sortKey: 'position_name_en' },
                { name: this.$t("employeeProfile.dateOfBirth"), sortKey: 'date_of_birth' },
                { name: this.$t("employeeProfile.phone") },
                { name: this.$t("employeeProfile.status") },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;"
                }
            ];
        }
    },
    methods: {
        addNew() {
            this.$router.push({ name: "employee-profile-create" });
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("hr/employeeProfile/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        edit(record) {
            this.$store.commit("hr/employeeProfile/SET_EDIT_DATA", record);
            this.$router.push({
                name: "employee-profile-edit",
                params: { id: record.employee_id }
            });
        },
        deleteRecord(record) {
            record._deleting = true;
            this.$store
                .dispatch("hr/employeeProfile/destroy", record.employee_id)
                .then(response => {
                    this.$notify({ type: "success", text: response.message });
                    this.fetchData();
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                })
                .finally(() => {
                    record._deleting = false;
                });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("hr/employeeProfile/RESET_STATE");
        next();
    }
};
</script>
